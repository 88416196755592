<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-2"
      >
        <div class="m-2">
          <b-row
            xl="5"
            md="12"
            sm="12"
          >
            <b-col>
              <h3>{{ $t('Product') }}</h3>

              <b-form class="p-2">
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      :label="$t('Code')"
                      label-for="register-code"
                    >
                      <b-form-input
                        id="register-code"
                        v-model="code"
                        name="register-code"
                        :placeholder="$t('Code')"
                      />

                      <small class="text-secondary">
                        *{{
                          $t(`If not specified, the warehouse will
                        be added automatically`)
                        }}
                      </small>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      :label="$t('Name')"
                      label-for="register-name"
                    >
                      <b-col
                        v-for="(item, index) in language"
                        :key="`${index}_name`"
                        cols="12"
                      >
                        <b-form-group
                          :label="$t(item.name)"
                          :label-for="item.name"
                        >
                          <b-form-input
                            v-if="name[index]"
                            :id="item.name"
                            :name="item.name"
                            :value="name[index]['value']"
                            :placeholder="$t(item.name)"
                            @input="
                              inputLanguage($event, item.lang, index, 'name')
                            "
                          />
                        </b-form-group>
                      </b-col>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      :label="$t('Category')"
                      label-for="category"
                    >
                      <v-select
                        id="category"
                        v-model="productCategoryId"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :options="respDataCategory"
                        :reduce="respDataCategory => respDataCategory._id"
                        class="select-size-lg"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      :label="$t('Bar Code')"
                      label-for="register-bar-code"
                    >
                      <b-form-input
                        id="register-bar-code"
                        v-model="barCode"
                        name="register-bar-code"
                        :placeholder="$t('Bar Code')"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>

              <h3>{{ $t('Price') }}</h3>

              <b-form class="p-2">
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      :label="$t('Selling price')"
                      label-for="register-selling-price"
                    >
                      <b-form-input
                        id="register-sellingPrice"
                        v-model="sellingPrice"
                        name="register-selling-price"
                        :placeholder="$t('Selling price')"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12">
                    <b-form-group
                      :label="$t('Cost price')"
                      label-for="register-cost-price"
                    >
                      <b-form-input
                        id="register-cost-price"
                        v-model="costPrice"
                        name="register-cost-price"
                        :placeholder="$t('Cost price')"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </b-col>
            <b-col
              xl="7"
              md="12"
              sm="12"
            >
              <h3>{{ $t('Image') }}</h3>
              <b-row>
                <b-col
                  v-for="(item, index) in 9"
                  :key="index"
                  xl="3"
                  md="4"
                  sm="6"
                >
                  <b-form-file
                    :ref="`fileInput_${index}`"
                    accept=".webp, .jpeg, .jpg, .png, .gif"
                    :hidden="true"
                    plain
                    @change="inputImageRenderer($event, index)"
                  />
                  <div
                    class="card-image-custom cursor-pointer"
                    @click="addFileInput(index)"
                  >
                    <div class="image-card-image-custom">
                      <b-img
                        v-if="srcImage(index)"
                        class="image-custom"
                        :src="srcImage(index)"
                        :alt="`image_${index}`"
                        height="80"
                      />
                      <img
                        v-else
                        class="image-custom"
                        :src="require('@/assets/images/blank/no_image.png')"
                        :alt="'no_image'"
                      >
                    </div>
                    <div class="text">
                      <p>
                        {{
                          index === 0
                            ? $t('Main product picture')
                            : `${$t('Picture')} ${index}`
                        }}
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </b-card>
      <b-card
        v-if="!dataId"
        no-body
        class="mb-2"
      >
        <div class="m-2">
          <b-row
            xl="5"
            md="12"
            sm="12"
          >
            <b-col>
              <h3>{{ $t('Warehouse') }}</h3>

              <b-form class="p-2">
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      :label="$t('Amount')"
                      label-for="register-amount"
                    >
                      <b-form-input
                        id="register-amount"
                        v-model="stock"
                        name="register-amount"
                        :placeholder="$t('Amount')"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12">
                    <b-form-group
                      :label="$t('The product arrives at')"
                      label-for="register-the-product-arrives-at"
                    >
                      <v-select
                        id="register-warehouse"
                        v-model="warehouseId"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :options="respDataWarehouse"
                        :reduce="respDataWarehouse => respDataWarehouse._id"
                        class="select-size-lg"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </b-col>
            <b-col
              xl="7"
              md="12"
              sm="12"
            />
          </b-row>
        </div>
      </b-card>
      <b-row class="mb-0">
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-dark"
            class="d-flex"
            @click="$router.go(-1)"
          >
            <span class="align-middle">{{ $t('Back') }}</span>
          </b-button>
        </b-col>

        <b-col
          cols="12"
          md="6"
          class="b-col-custom"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="d-flex"
              @click="validationForm()"
            >
              <span class="align-middle">{{ $t('Save') }}</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormFile,
  BCardGroup,
  BCardFooter,
  BCardBody,
  BCardTitle,
  BCard,
  BRow,
  BCol,
  BImg,
  BForm,
  BAvatar,
  BFormInput,
  BButton,
  BFormGroup,
  BTable,
  BPagination,
  BDropdown,
  BOverlay,
  BCardText,
  BInputGroupAppend,
  BInputGroup,
  BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import perPageOptions from '@/perPageOptions'
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/store/services/eCommerce/warehouseProduct'
import storeModuleCategory from '@/store/services/eCommerce/productCategory'
import storeModuleWarehouse from '@/store/services/eCommerce/warehouse'

import debounce from 'debounce'

const STORE_MODULE_NAME = 'warehouseProduct'
const STORE_MODULE_NAME_CATEGORY = 'productCategory'
const STORE_MODULE_NAME_WAREHOUSE = 'warehouse'

export default {
  components: {
    BFormFile,
    BCardGroup,
    BCardFooter,
    BCardBody,
    BCardTitle,
    BCard,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    BForm,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    BFormInput,
    BButton,
    BFormGroup,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BCardText,
    BInputGroupAppend,
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    // BFormFile,
    // BBadge,
    // BLink,
    // BMedia,
    // BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      avatarText,
      dataId: null,
      // images: [null, null, null, null, null, null, null, null, null],
      images: {
        pic1: '',
        pic2: '',
        pic3: '',
        pic4: '',
        pic5: '',
        pic6: '',
        pic7: '',
        pic8: '',
        pic9: '',
      },
      name: [],
      code: '',
      productCategoryId: '',
      barCode: '',
      sellingPrice: '',
      costPrice: '',
      stock: '',
      warehouseId: '',
      show: false,
    }
  },
  computed: {
    respDataWarehouse() {
      return store.state[STORE_MODULE_NAME_WAREHOUSE].respData != null
        ? store.state[STORE_MODULE_NAME_WAREHOUSE].respData.data.map(e => ({
          _id: e._id,
          name: this.showFromCurrentLanguage(e.name),
        }))
        : []
    },
    respDataCategory() {
      return store.state[STORE_MODULE_NAME_CATEGORY].respData != null
        ? store.state[STORE_MODULE_NAME_CATEGORY].respData.data.map(e => ({
          _id: e._id,
          name: this.showFromCurrentLanguage(e.name),
        }))
        : []
    },
    language() {
      return store.state.appConfig.systemConfig
        ? store.state.appConfig.systemConfig.language
        : []
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    if (!store.hasModule(STORE_MODULE_NAME_CATEGORY)) store.registerModule(STORE_MODULE_NAME_CATEGORY, storeModuleCategory)
    if (!store.hasModule(STORE_MODULE_NAME_WAREHOUSE)) store.registerModule(STORE_MODULE_NAME_WAREHOUSE, storeModuleWarehouse)
    if (this.$route.params.id !== 'add') {
      store
        .dispatch(`${STORE_MODULE_NAME}/view`, { id: this.$route.params.id })
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          let { data } = result.data
          console.log(data)
          data = data[0]
          this.show = false
          this.dataId = data._id
          for (let index = 0; index < data.images.length; index++) {
            if (index === 0) {
              this.images.pic1 = data.images[index]
            } else if (index === 1) {
              this.images.pic2 = data.images[index]
            } else if (index === 2) {
              this.images.pic3 = data.images[index]
            } else if (index === 3) {
              this.images.pic4 = data.images[index]
            } else if (index === 4) {
              this.images.pic5 = data.images[index]
            } else if (index === 5) {
              this.images.pic6 = data.images[index]
            } else if (index === 6) {
              this.images.pic7 = data.images[index]
            } else if (index === 7) {
              this.images.pic8 = data.images[index]
            } else if (index === 8) {
              this.images.pic9 = data.images[index]
            }
          }
          // this.images = []
          this.name = data.name
          this.code = data.code
          this.productCategoryId = data.productCategoryId
          this.barCode = data.barCode
          this.sellingPrice = data.sellingPrice
          this.costPrice = data.costPrice
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          // eslint-disable-next-line no-unused-vars
        })
        .catch(error => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // console.log('fetchUsers Error : ', error)
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    }

    if (this.name.length !== this.language.length) {
      for (let index = 0; index < this.language.length; index += 1) {
        const indexLanguage = this.name.findIndex(
          element => element.lang === this.language[index].lang,
        )
        if (indexLanguage < 0) {
          this.name.push({
            lang: this.language[index].lang, // th , en
            value: '',
          })
        }
      }
    }
    const obj = {
      currentPage: 1,
      pageSize: 0,
      searchQuery: '',
    }
    store
      .dispatch(`${STORE_MODULE_NAME_CATEGORY}/get`, obj)
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false
        //
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        // eslint-disable-next-line no-unused-vars
      })
      .catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
    store
      .dispatch(`${STORE_MODULE_NAME_WAREHOUSE}/get`, obj)
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false
        //
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        // eslint-disable-next-line no-unused-vars
      })
      .catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    if (store.hasModule(STORE_MODULE_NAME_CATEGORY)) store.unregisterModule(STORE_MODULE_NAME_CATEGORY)
  },
  setup() {
    return {
      STORE_MODULE_NAME,
      STORE_MODULE_NAME_CATEGORY,
      STORE_MODULE_NAME_WAREHOUSE,
    }
  },
  methods: {
    srcImage(index) {
      if (index === 0) {
        return this.images.pic1
      }
      if (index === 1) {
        return this.images.pic2
      }
      if (index === 2) {
        return this.images.pic3
      }
      if (index === 3) {
        return this.images.pic4
      }
      if (index === 4) {
        return this.images.pic5
      }
      if (index === 5) {
        return this.images.pic6
      }
      if (index === 6) {
        return this.images.pic7
      }
      if (index === 7) {
        return this.images.pic8
      }
      if (index === 8) {
        return this.images.pic9
      }
      return null
    },
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },
    inputLanguage(input, lang, index, type) {
      if (type === 'name') {
        if (this.name[index].lang === lang) {
          this.name[index].value = input
        }
      }
    },
    addFileInput(index) {
      // Add a new null element to the fileInputs array
      this.$refs[`fileInput_${index}`][0].$el.click()
    },
    inputImageRenderer(input, index) {
      // eslint-disable-next-line prefer-destructuring
      if (input.target.files && input.target.files[0]) {
        // if (input.target.files[0].size > 300000) {
        //     this.$toast({
        //         component: ToastificationContent,
        //         position: 'bottom-right',
        //         props: {
        //             title: this.$t('Error'),
        //             icon: 'ErrorIcon',
        //             variant: 'danger',
        //             text: `${this.$t('Allowed')} JPG, GIF ${this.$t('or')} PNG. ${this.$t('Max size of')} 300KB`,
        //         },
        //     })
        //     return
        // }

        const reader = new FileReader()
        reader.onload = e => {
          if (index === 0) {
            this.images.pic1 = e.target.result
          } else if (index === 1) {
            this.images.pic2 = e.target.result
          } else if (index === 2) {
            this.images.pic3 = e.target.result
          } else if (index === 3) {
            this.images.pic4 = e.target.result
          } else if (index === 4) {
            this.images.pic5 = e.target.result
          } else if (index === 5) {
            this.images.pic6 = e.target.result
          } else if (index === 6) {
            this.images.pic7 = e.target.result
          } else if (index === 7) {
            this.images.pic8 = e.target.result
          } else if (index === 8) {
            this.images.pic9 = e.target.result
          }
          // this.images[index] = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    validationForm() {
      const obj = {
        id: this.dataId,
        // images: this.images.filter(e => e !== null),
        images: Object.values(this.images).filter(e => e !== null && e !== ''),
        name: this.name,
        code: this.code,
        productCategoryId: this.productCategoryId,
        barCode: this.barCode,
        sellingPrice: this.sellingPrice,
        costPrice: this.costPrice,
        stock: this.stock,
        warehouseId: this.warehouseId,
      }

      this.show = true

      if (this.dataId !== null) {
        delete obj.stock
        delete obj.warehouseId

        store
          .dispatch(`${STORE_MODULE_NAME}/update`, obj)
          .then(result => {
            this.show = false
            this.$router.go(-1)

            // this.showToast('success', 'bottom-right')
          })
          .catch(error => {
            console.log('fetchUsers Error : ', error)
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Error'),
                icon: 'ErrorIcon',
                variant: 'danger',
                text: this.$t(error.response.data.message),
              },
            })
            this.show = false

            // this.showToast('danger', 'bottom-right')
          })
      } else {
        delete obj.id
        store
          .dispatch(`${STORE_MODULE_NAME}/add`, obj)
          .then(result => {
            this.show = false
            this.$router.go(-1)
            // this.showToast('success', 'bottom-right')
          })
          .catch(error => {
            console.log('fetchUsers Error : ', error)
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Error'),
                icon: 'ErrorIcon',
                variant: 'danger',
                text: this.$t(error.response.data.message),
              },
            })
            this.show = false

            // this.showToast('danger', 'bottom-right')
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.b-col-custom {
  margin-top: 0.5rem;
}

.card-image-custom {
  text-align: center;
  margin: 8px;
  border: 1px solid rgb(202, 202, 202);

  border-radius: 10px;
}

.image-card-image-custom {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.image-custom {
  width: 100%;
  height: 140px;
  object-fit: cover;
}
</style>
